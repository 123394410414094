import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { LogoBlue, LogoWhite } from 'assets'
import { Fragment, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { BroadcastHeader } from './BroadcastHeader'
import { getNavigations } from './navigations'

function _Header({ type = 'white' }: { type: 'white' | 'dark' }) {
  const navigations = getNavigations()
  const location = useLocation()
  const color = useMemo(() => {
    if (type == 'white') return 'white'
    else if (type == 'dark') return 'indigo-800'
  }, [type])

  const isMain = type == 'dark'

  const activeMenu = (to: string) => {
    if (location.pathname.startsWith(to)) {
      return `border-b-${isMain ? 'white' : 'indigo-800'}`
    }
    return ''
  }

  return (
    <Popover className={`${isMain ? `bg-${color}` : ''} relative z-50`}>
      <BroadcastHeader />
      <div className="content relative py-4 z-10">
        <nav className="relative flex items-center" aria-label="Global">
          <div className="flex items-center flex-grow flex-shrink-0 md:flex-grow-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/home">
                <span className="sr-only">Nextres</span>
                {isMain ? <LogoWhite /> : <LogoBlue />}
              </Link>
              <div className="flex items-center md:hidden">
                <div className="mx-4">
                  <span className={`block rounded w-[1px] h-5 bg-${isMain ? 'white' : 'gray-400'}`}></span>
                </div>
                <Popover.Button
                  className={`rounded-md p-2 inline-flex items-center justify-center text-${
                    isMain ? 'white' : 'gray-500'
                  } hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
          </div>
          <div className="hidden flex-wrap items-center md:flex md:ml-10 md:space-x-4 flex-1 justify-between">
            {Object.keys(navigations).map((key, index) => {
              const item: any = navigations[key]
              if (!item.visible) return null
              if (!item.href) return <div className={item.className} key={`nav-${item.name}-${index}`} />

              return (
                <Link
                  key={`nav-${item.name}-${index}`}
                  to={item.href}
                  className={`py-3 my-2 text-sm font-semibold text-${isMain ? 'white' : 'gray-600'} rounded ${
                    activeMenu(item.href) || (isMain ? 'border-b-indigo-800' : 'border-b-white/0')
                  } ${item.className || 'border-b-4  hover:border-b-gray-300'}`}
                >
                  <span dangerouslySetInnerHTML={{ __html: item.name }} />
                </Link>
              )
            })}
          </div>
        </nav>
      </div>
      <Transition
        as={Fragment}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>{isMain ? <LogoWhite /> : <LogoBlue />}</div>
              <div className="-mr-2">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Close main menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              {Object.keys(navigations).map((key, index) => {
                const item: any = navigations[key]
                if (!item.visible) return null

                return (
                  <Link
                    key={`n-${item.name}-${index}`}
                    to={item.href}
                    className={`block px-3 py-2 rounded-md text-sm text-gray-600 font-bold hover:text-gray-900 hover:bg-gray-50 ${activeMenu(
                      item.href,
                    )}`}
                  >
                    <span dangerouslySetInnerHTML={{ __html: item.name }} />
                  </Link>
                )
              })}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}

export const MainHeader = _Header
