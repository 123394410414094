import type { InputType } from 'config'
import { cloneDeep } from 'lodash'
import moment from 'moment-timezone'

import { removeComma } from './convertor'

const REQUIRED_FIELD = 'Required field'
export function validateEmail(email: string) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function isHTML(str: string) {
  var a = document.createElement('div')
  a.innerHTML = str

  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true
  }

  return false
}

function validateNumberLetters(val: string, count: number) {
  let numbers = 0
  val = val.toString()
  for (let i = 0; i < val.length; i += 1) {
    if (val[i] >= '0' && val[i] <= '9') numbers += 1
  }
  return numbers === count
}

function validCreditScore(val: string) {
  let numbers = 0
  val = val.toString()
  for (let i = 0; i < val.length; i += 1) {
    if (val[i] >= '0' && val[i] <= '9') numbers += 1
  }
  return numbers === 3
}

export const isValidDate = function (dateString: string) {
  try {
    var regEx = /^\d{4}-\d{2}-\d{2}$/
    if (!dateString.match(regEx)) return false // Invalid format
    var d = new Date(dateString)
    var dNum = d.getTime()
    if (!dNum && dNum !== 0) return false // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString
  } catch {
    return false
  }
}

export const isValidZipcode = function (value: string) {
  try {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
  } catch {
    return false
  }
}

function loanAmountRestriction(value: any, loanNumber: any) {
  value = removeComma(value)
  let isPrevLoan = true
  const _loanNumber = loanNumber
  if (loanNumber === 'New') isPrevLoan = false
  loanNumber = Number(loanNumber)
  if (loanNumber >= 1286 && loanNumber < 3000000) isPrevLoan = false

  let error = ''
  if (isPrevLoan) {
    if (removeComma(value) < 75000 || removeComma(value) > 5000000) {
      error = 'Must be in 75,000 - 5,000,000'
    }
  } else {
    if (loanNumber >= 1965 || _loanNumber === 'New') {
      if (removeComma(value) < 200000 || removeComma(value) > 5000000) {
        error = 'Must be in 200,000 - 5,000,000'
      }
    } else {
      if (removeComma(value) < 150000 || removeComma(value) > 5000000) {
        error = 'Must be in 150,000 - 5,000,000'
      }
    }
  }

  // Specific Exception Loan Number Limit
  //1444: 100,000
  if (loanNumber === 1444) {
    error = ''
    if (removeComma(value) < 100000 || removeComma(value) > 5000000) {
      error = 'Must be in 100,000 - 5,000,000'
    }
  }

  //1394: 90,000
  if (loanNumber === 1394) {
    error = ''
    if (removeComma(value) < 90000 || removeComma(value) > 5000000) {
      error = 'Must be in 90,000 - 5,000,000'
    }
  }

  //1427: 149,500
  if (loanNumber === 1427) {
    error = ''
    if (removeComma(value) < 149500 || removeComma(value) > 5000000) {
      error = 'Must be in 149,500 - 5,000,000'
    }
  }

  return error
}

function asIsValueRestriction(value: any, loanNumber: any) {
  value = removeComma(value)
  let isPrevLoan = true
  if (loanNumber === 'New') isPrevLoan = false
  loanNumber = Number(loanNumber)
  if (loanNumber >= 1340 && loanNumber < 3000000) isPrevLoan = false

  let error = ''
  if (isPrevLoan) {
    if (removeComma(value) < 20000) {
      return 'Minimum value is 20,000'
    }
  } else {
    if (removeComma(value) < 50000) {
      return 'Minimum value is 50,000'
    }
  }
  return error
}

export const InputValidate = function (data: any, loanNumber: any = -1) {
  let error = ''
  const {
    options,
    key = '',
    value = '',
    inputType,
    visible = true,
    required = false,
    disabled = false,
    allowDefaultOption,
    length = 0,
    minLength = 0,
    maxLength = 0,
    minNumberRange = 0,
    maxNumberRange = 0,
  } = data
  if (!visible) return ''
  if (disabled) return ''
  if (value && length && value.length != length) return `Length must be ${length}.`
  if (value && minLength && value.length < minLength) return `Minimum length must be ${minLength}.`
  if (value && maxLength && value.length > maxLength) return `Maximum length must be ${maxLength}.`
  if (value && minNumberRange && Number(String(value).replace(/,/g, '')) < minNumberRange)
    return `Minimum value must be bigger than ${minNumberRange}`
  if (value && maxNumberRange && Number(String(value).replace(/,/g, '')) > maxNumberRange)
    return `Maximum value must be less than ${maxNumberRange}`

  if (!required) return ''
  if (['section', 'group', 'divider', 'alert'].indexOf(inputType.toLowerCase()) !== -1) return ''
  if (value === null || value.toString().length === 0) error = REQUIRED_FIELD
  if (inputType.toLowerCase() === 'select') {
    if (value === '') {
      if (allowDefaultOption) error = ''
      else error = REQUIRED_FIELD
    } else {
      if (Array.isArray(options)) {
        if (options.indexOf(value) === -1) error = REQUIRED_FIELD
      } else {
        if (options[value] === undefined) error = REQUIRED_FIELD
      }
    }
  } else if (inputType.toLowerCase() === 'table') {
    if (value.length == 0) error = REQUIRED_FIELD
  }

  if (inputType.toLowerCase() === 'multiselect') {
    const selected = Object.keys(value).filter((key) => value[key])
    if (!selected.length) return REQUIRED_FIELD
  }

  if (inputType.toLowerCase() === 'filetable') {
    if (!value.length) return REQUIRED_FIELD
  }

  switch (key) {
    case 'proposedOccupancy':
      if (value === 'Owner Occupied') error = `Can not be 'Owner Occupied'`
      break
    case 'proposedLoanAmount':
      return loanAmountRestriction(value, loanNumber)
      break
    case 'asIsValue':
      return asIsValueRestriction(value, loanNumber)
      break
    case 'estimatedCreditScore':
      let creditScoreMin = 550
      if (removeComma(value) < creditScoreMin) {
        return `Must be greater than ${creditScoreMin}`
      }
      break
    case 'bankRoutingNumber':
      if (!validateNumberLetters(value, 9)) {
        return `Bank Routing Number must be 9 digits.`
      }
      break
    case 'zipCode':
      if (!isValidZipcode(value)) {
        return `Invalid Code.`
      }
      break
  }
  if (error.length > 0) return error
  if (data.inputType.toLowerCase() === 'autocomplete') return error
  try {
    switch (data.type) {
      case 'date':
        if (!isValidDate(data.value)) {
          error = 'Invalid Date Format.'
        }
        break
      case 'email':
        if (!validateEmail(data.value)) {
          error = 'Email address is not in a valid format.'
        }
        break
      case 'phone':
        if (!validateNumberLetters(data.value, 10)) {
          error = 'Must be 10 digits.'
        }
        break
      case 'ssn':
      case 'entityTaxID':
        if (!validateNumberLetters(data.value, 9)) {
          error = 'Must be 9 digits.'
        }
        break
      case 'creditScore':
        if (!validCreditScore(data.value)) {
          error = 'Must be 3 digits.'
        }
        break
    }
  } catch {}
  return error
}

export const validateInputs = (inputStates: Record<string, InputType>) => {
  let hasError = false
  const newStats = cloneDeep(inputStates)
  const data: Record<string, any> = {}
  for (const key in newStats) {
    const { type, value, disabled = false } = newStats[key]
    let error = InputValidate(newStats[key])
    newStats[key].error = error
    if (error.length > 0) hasError = true

    if (!disabled && value !== undefined) {
      if (type == 'thousandSep' && typeof value == 'string') data[key] = Number(String(value).replace(/,/g, ''))
      else data[key] = value
    }
  }
  return {
    hasError,
    data,
    newInputStates: newStats,
  }
}

export const validDateMMDDYYYY = (date: string) => {
  let rlt = false
  try {
    rlt = moment(date, 'MM/DD/YYYY', true).isValid()
  } catch {
    rlt = false
  }
  return rlt
}
