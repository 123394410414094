import { ClockIcon, CloudArrowUpIcon } from '@heroicons/react/24/outline'
import { TargetBox } from 'components/DragDrop'
import { useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend'
import type { Color } from 'stories/types'

import { Tooltip } from '../Tooltip/Tooltip'

interface InputFileProps {
  /**
   * What background color to use
   */
  color?: Color
  /**
   * Is Full
   */
  full?: boolean
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Title of Input
   */
  title?: string
  /**
   * Name of Input
   */
  name?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string
  /**
   * Is has icon
   */
  hasIcon?: boolean
  /**
   * Required
   */
  required?: boolean
  /**
   * Icon component
   */
  icon?: string | JSX.Element | null

  multiple?: boolean

  acceptFileTypes?: string

  history?: boolean

  value?: string | File | FileList | File[]

  ref?: React.RefObject<HTMLInputElement> | null
  /**
   * Icon component
   */
  onChange: (e: FileList | File[] | File | null) => void // string | React.ChangeEvent<HTMLInputElement>) => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const InputFile = ({
  disabled = false,
  tooltip = '',
  title = '',
  name = '',
  error = '',
  className = '',
  hasIcon = false,
  icon = null,
  required = false,
  multiple = false,
  acceptFileTypes = 'application/pdf',
  history = false,
  ref = null,
  value = '',
  onChange = () => {},
  showHistory = () => {},
  ...props
}: InputFileProps) => {
  if (!name) name = `file-input-${Date.now()}`

  const strValue = useMemo(() => {
    if (!value) return null
    try {
      if (typeof value === 'string') {
        const index = value.lastIndexOf('/')
        return value.substring(index + 1, value.length)
      }
      if (Array.isArray(value)) return value.map((v) => v.name).join(', ')
      else if (value instanceof FileList) {
        const fileNames = []
        for (let i = 0; i < value.length; i += 1) fileNames.push(value.item(i)?.name)
        return fileNames.join(', ')
      } else {
        return value.name
      }
    } catch (e) {
      console.log(e)
    }

    return 'File Selected'
  }, [value])

  const onDrop = ({ files }: { files: File[] }) => {
    if (disabled) return
    onChange(files?.length ? (multiple ? files : files[0]) : null)
  }

  return (
    <div className="group input-container mt-4 max-w-[500px]">
      <label
        className={`flex items-center text-[14px] text-gray-900 dark:text-gray-400 duration-300 origin-[0] mb-2 rounded ${
          disabled ? '' : 'bg-white'
        } peer-placeholder-shown:-z-10 peer-focus:z-10 ${hasIcon && icon ? 'pl-8' : ''} peer-focus:pl-1 left-0 flex`}
      >
        {title}
        {required && '*'}
        {tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
        {history && (
          <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
            <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
          </span>
        )}
      </label>

      <div
        className={`border-dashed border-2 border-gray-300 text-gray-400 max-w-[300px] text-center mb-1 ${className}`}
      >
        <DndProvider backend={HTML5Backend}>
          <TargetBox types={[NativeTypes.FILE]} onDrop={(docs) => onDrop(docs)}>
            <div className="py-2">
              <CloudArrowUpIcon className="w-8 h-8 mx-auto text-gray-600" />
              <p className="text-gray-400 text-[15px]">Drag and drop files</p>
              <p className="leading-[15px]">or</p>
              <label
                htmlFor={name}
                className="text-shade-blue text-sm cursor-pointer hover:text-blue-800 hover:underline"
              >
                Click here to browse files
              </label>
              <input
                type="file"
                name={name}
                id={name}
                className="hidden"
                disabled={disabled}
                required={required}
                onChange={(e) =>
                  onChange(e.target.files?.length ? (multiple ? e.target.files : e.target.files?.item(0)) : null)
                }
                accept={acceptFileTypes}
                ref={ref}
                multiple={multiple}
                {...props}
              />
            </div>
          </TargetBox>
        </DndProvider>
      </div>
      {!strValue ? (
        acceptFileTypes && (
          <p className="peer-invalid:visible text-gray-400 text-[14px] pl-1">Accepted File Types: {acceptFileTypes}</p>
        )
      ) : (
        <p className="peer-invalid:visible italic text-sm pl-1">{strValue}</p>
      )}
      {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1 mt-2">{error}</p>}
    </div>
  )
}
