import { BaseLayout } from 'layouts'

import { BaseRoutes } from './routes'

function App() {
  return (
    <BaseLayout>
      <BaseRoutes />
    </BaseLayout>
  )
}

export default App
