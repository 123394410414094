export enum AccountType {
  Individual = 'individual',
  Ira = 'ira',
  Trust = 'trust',
  Company = 'company',
  Admin = 'admin',
  FundingManager = 'fundingManager',
}

export const AccountTypeText: Record<string, string> = {
  individual: 'Individual',
  ira: 'Ira',
  trust: 'Trust',
  company: 'Company',
  admin: 'Admin',
  fundingManager: 'Funding Manager',
}

export const ManagerAccountTypes = [AccountType.Admin, AccountType.FundingManager]

export const isManager = (type: AccountType) => ManagerAccountTypes.includes(type)
