import './style.scss'

import { useTitle } from 'utils'

export const PrivacyPolicyPage = () => {
  useTitle('Privacy Policy')

  return (
    <div className="content pt-16 content-privacy-policy">
      <p className="text-4xl font-semibold mb-10 text-center">Privacy Policy</p>

      <section>
        <h2>Introductory paragraph</h2>
        <p>
          At Nextres, LLC, your trust and the security of your information is very important to us. This privacy policy
          explains what we do to protect and maintain your information and to let you know how you can limit the
          information that we share with others. Thank you for visiting our website at
          <a target="_blank" rel="noreferrer" href="https://nextres.com/">
            www.nextres.com
          </a>
          and please feel free to contact us if you have any questions.
        </p>
      </section>
      <section>
        <h2>Disclosure Notice to Residents of the State of California</h2>
        <p>Please see below regarding your privacy rights.</p>
      </section>
      <section>
        <h2>Disclosure Notice to Residents of all other states</h2>
        <p>
          Please see the below disclosures and information related to protecting, collecting, sharing, and using your
          personal information. “Site” refers to the above-captioned website of
          <a target="_blank" rel="noreferrer" href="https://nextres.com/">
            www.nextres.com
          </a>
          and its related services. “You” and “User” refers to a registered user of this Site. “Nextres” and/or
          “Nextres, LLC” refers to Nextres, LLC.
        </p>
      </section>
      <section>
        <h2>The Work Space</h2>
        <p>
          This area of the Site is intended for the benefit of Users. The private and shared Work Space areas of the
          Site help to facilitate communication and the exchange and sharing of information.
        </p>
      </section>
      <section>
        <h2>Information that Nextres, LLC Collects</h2>
        <h3>We collect nonpublic personal information about You, including, but not limited, to:</h3>
        <ul>
          <li>
            Information We receive from you on applications, online registrations or other forms (such as your name,
            address, social security number, phone, fax, email address, street address, assets, income, debt and more).
          </li>
          <li>
            Information about your transactions with us, our affiliates or others such as account balance, transaction
            history, or information about our communications with you.
          </li>
          <li>
            Information about your creditworthiness, credit scores and other facts about you that We obtain from
            consumer financial agencies or from providers of marketing and demographic information.
          </li>
          <li>
            Information about your employment, employment history, your past real estate transactions, your property
            insurance coverage and more.
          </li>
        </ul>
        <p>
          Pursuant to various laws, we are required to verify the information that you provide to us but We greatly
          appreciate your honesty in accurately representing your financial information, history and ability to repay
          any current or future credit obligations.
        </p>
      </section>
      <section>
        <h2>Information is Collected from Various Sources</h2>
        <h3>We collect information from various sources including, but not limited to:</h3>
        <ul>
          <li>Information collected from you that you provided to us by telephone and on-line.</li>
          <li>
            Information collected from third parties such as credit bureaus, lead generators and other companies who may
            have data on you.
          </li>
          <li>
            Information from cookies and other tracking technologies. Nextres, LLC automatically collects your browser
            type and browser software version to tailor the presentation of services to your platform and to maintain a
            record of your activity on the Site. Nextres, LLC may also use cookies to enable users to specify unique
            preferences and to track user tends and patterns.
          </li>
        </ul>
      </section>
      <section>
        <h2>Information Use and Disclosure</h2>
        <div>
          <h3>Protecting the Confidentiality and Security of Your Information</h3>
          <p>
            Keeping financial information is one of our most important responsibilities. Only those individuals who need
            access to your information to perform his or her job responsibilities are authorized to access your
            information. We take commercially reasonable precautions to protect your information and limit disclosure by
            maintaining physical, electronic and procedural safeguards. We also share your information with others as
            required by law or as necessary to obtain credit approval for your loan.
          </p>
        </div>
        <div>
          <h3>With affiliates of Nextres, LLC</h3>
          <p>
            While Nextres, LLC does not currently share user information with affiliates, we reserve the right to do so
            in the future.
          </p>
        </div>
        <div>
          <h3>With Third Party Service Providers, Joint Marketers and as Otherwise Permitted by Law</h3>
          <p>
            We may work with non-affiliated companies or organizations to provide services on our behalf in connection
            with obtaining approval for your loan or to provide you with opportunities to buy products or services
            offered by us or jointly with other financial institutions. We may also share information collected with
            third parties that is not personally identifying where not prohibited by law. As a result, we may disclose
            some of the information that we gather from you. For your protection, we require that these companies keep
            all personal information confidential.
          </p>
        </div>
        <div>
          <h3>With government agencies, courts, and credit bureaus</h3>
          <ul>
            <li>In response to a subpoena or similar government investigation;</li>
            <li>In response to a court order;</li>
            <li>
              In order for Nextres, LLC to be compliant with all federal and state agencies, laws, and government
              regulations.
            </li>
          </ul>
        </div>
      </section>
      <section>
        <h2>Cookies</h2>
        <p>
          We may use cookies and other technologies to gather information about your online computer usage, which may
          allow a third party to track your online computer usage.
        </p>
      </section>
      <section>
        <h2>Compliance with Children’s Online Privacy Protection Rule</h2>
        <p>
          Nextres, LLC does not collect personal information from minors under the age of eighteen (18) years of age.
          Nextres, LLC’s services are intended for adult individuals eighteen (18) years of age or older. If Nextres,
          LLC receives any information from a person under the age of eighteen (18) years of age, said information
          and/or documentation will be deleted.
        </p>
      </section>
      <section>
        <h2>Opting Out</h2>
        <p>
          You may opt out, that is, you may direct us not to share your information, by calling us at 833-639- 4140.
        </p>
      </section>
      <section>
        <h2>Registration information</h2>
        <p>
          We use nonpublic personal information for our own internal purposes, including contacting You via email to
          inform You about updates to our services and providing You with other information that we deem necessary. We
          reserve the right to disclose your information as required by law. We do not rent or sell our Users non-public
          personal information to third parties.
        </p>
      </section>
      <section>
        <h2>Advertising</h2>
        <p>
          At this time, we do not display banner advertising on our site, but we may send You email communications
          including information that we think may be of interest to You. You may opt out of receiving these promotional
          email communications at any time by contacting us.
        </p>
      </section>
      <section>
        <h2>Demographic Information, Surveys and Contests</h2>
        <p>
          The demographic information that Nextres, LLC collects in the registration process and through online surveys
          may be used to help us improve our services to meet the needs and preferences of our Users. This may include
          targeting advertising to You about our services. Information we gather through a contest may also be disclosed
          to third parties as necessary for prize fulfillment and other aspects of any contest or similar offering.
        </p>
      </section>
      <section>
        <h2>Comments submitted by Users</h2>
        <p>
          Nextres, LLC constantly seeks to improve the quality of the services We provide and the User experience on the
          Site. We value your input and encourage You to help us expand and make improvements to the Site by providing
          Us with any thoughts or comments You might have regarding our services or the operation of the Site. You can
          submit any comments by contacting us. If You provide this information, we will only use it to acknowledge or
          respond to your comments. In certain circumstances, we may ask for your permission to post your comments in
          marketing or we will use commercially reasonable methods to keep nonpublic personal information securely in
          our files and systems. Please note that with current technology, we cannot guarantee the security of your data
          as it travels over the internet.
        </p>
      </section>
      <section>
        <h2>How We Respond to Browser Do Not Track Requests</h2>
        <p>
          Nextres, LLC and other parties not affiliated with us may collect nonpublic personal information about your
          online activities over time and across different websites when you use our website or the services offered on
          our website. Industry standards regarding how to handle “Do Not Track” requests from web browsers are still
          evolving and there is currently no set of industry standards for handling such requests. As such, we may not
          separately respond to take any action with respect to a “do not track” configuration set in your internet
          browser. At this time, we do not have any protocol in place that allows you to opt-out of internet tracking.
          If you have any questions regarding our internet tracking methods, please contact us. There are also options
          of blocking cookies in your browser; blocking advertising through your mobile device settings; and/or
          utilizing privacy plug-ins or browsers.
        </p>
      </section>
      <section>
        <h2>Correcting/Updating Your Information</h2>
        <p>
          To correct and/or update your information or if you have any questions about this privacy policy, please
          contact us at:
        </p>
        <p>
          <b>Nextres, LLC</b>
          <br />
          <b>12 Penns Trail Suite 138 Newtown, PA 18940</b>
          <br />
          <b>Main Phone: 833-639-4140</b>
        </p>
      </section>
      <section>
        <h2>Notice to California Residents</h2>
        <p>
          We are required by the California Consumer Privacy Act of 2018 (“CCPA”) to provide this CCPA Notice to
          California residents to explain how we collect, use and share their personal information, and the rights and
          choices we offer California residents regarding our handling of their personal information.
        </p>
      </section>
      <section>
        <h2>CCPA Scope and Exclusions</h2>
        <p>
          This CCPA Notice, including the description of our Privacy Practices and your Privacy Rights, apply only to
          California residents whose interactions with us are limited to:
        </p>
        <ol>
          <li>Visiting our consumer websites;</li>
          <li>Signing up for email alerts;</li>
          <li>Commenting on or contributing to posts, blogs, or videos on our website</li>
        </ol>
        <p>This CCPA Notice does not apply to the personal information we collect, use or disclose about:</p>
        <ol>
          <li>
            Consumer who initiate or complete the process of applying for financial products or services. This is
            because this information is subject to the federal Gramm-Leach-Bliley Act (“GLBA”) and implementing
            regulations, or the California Financial Information Privacy Act (“FIPA”), or Representatives of businesses
            that seek to obtain our products or services, or to provide products or services to use.
          </li>
        </ol>
      </section>
      <section>
        <h2>Privacy Rights</h2>
        <p>The CCPA grants individuals the following rights:</p>

        <div>
          <h3>Information.</h3>
          <p>
            You can request information about how we have collected, used and shared your Personal Information during
            the past 12 months.
          </p>
        </div>
        <div>
          <h3>Access</h3>
          <p>You can request a copy of the personal information that we maintain about you.</p>
        </div>
        <div>
          <h3>Deletion</h3>
          <p>
            You can ask us to delete the personal information that we collect or maintain about you. Please note that
            CCPA limits these rights by, for example, prohibiting us from providing certain sensitive information in
            response to an access request and limiting the circumstances in which we must comply with a deletion
            request. We will also respond to requests for information and access only to the extent we are able to
            associate with a reasonable effort the information we maintain with the identifying details you provide in
            your request. If we deny your request, we will communicate our decision to you. You are entitled to exercise
            the rights described above free from discrimination.
          </p>
        </div>
      </section>
      <section>
        <h2>How to Submit a Request</h2>
        <p>
          To request access to or deletion of personal information: Call
          <b>833-639-4140</b>
        </p>

        <div>
          <h3>Identity verification</h3>
          <p>
            The CCPA requires us to verify the identity of the individual submitting a request to access of delete
            personal information before providing a substantive response to the request. We will ask you to verify your
            identity when you submit a request.
          </p>
        </div>
        <div>
          <h3>Authorized agents</h3>
          <p>
            California residents can empower an “authorized agent” to submit requests on their behalf. We will require
            the authorized agent to have a written authorization confirming that authority.
          </p>
        </div>
      </section>
    </div>
  )
}
