interface ButtonGroupProps {
  /**
   * Title of Input
   */
  title?: Array<string> | Record<string, string>
  /**
   * Value of Input
   */
  value?: string
  /**
   * Custom class name
   */
  className?: string
  onChange?: (checked: any) => void
}

/**
 * Primary UI component for user interaction
 */
export const ButtonGroup = ({
  title = [],
  value = '',
  className = '',
  onChange = () => {},
  ...props
}: ButtonGroupProps) => {
  const length = Array.isArray(title) ? title.length : Object.keys(title).length

  const renderButton = (t: string, v: string, index: number) => {
    const classes = [
      'py-1 px-2 text-xs border-r border-gray-200 focus:ring-2 focus:ring-indigo-700 focus:text-indigo-700 cursor-pointer',
    ]
    const isActive = value == v
    const isFirst = index == 0
    const isLast = index == length - 1

    if (isActive) classes.push('text-shade-indigo ring-shade-indigo text-white bg-indigo-500')
    else classes.push('bg-white text-gray-500 hover:bg-gray-100')

    if (isFirst) classes.push('rounded-l border')
    if (isLast) classes.push('rounded-r border')
    if (!isFirst && !isLast) classes.push('border-t border-b')

    classes.push(className)

    return (
      <a aria-current="page" className={classes.join(' ')} onClick={() => onChange(v)} key={`${title}-${index}`}>
        {t}
      </a>
    )
  }

  return (
    <div className="inline-flex rounded-md flex-wrap" {...props}>
      {Array.isArray(title) && title.map((t, index) => renderButton(t, t, index))}

      {!Array.isArray(title) && Object.keys(title).map((key, index) => renderButton(title[key], key, index))}
    </div>
  )
}
