import {
  API_ACCOUNT_EXECUTIVE,
  API_ACCOUNT_EXECUTIVE_BY_ID,
  API_ACCOUNT_EXECUTIVES,
  API_BASE_SUBMIT_USER,
  API_FAQ,
  API_FUND_DRAW_REQUEST,
  API_SETTING,
} from 'config'
import Api from 'services/api'

export const submitUserForm = (data: Record<string, any>) => {
  return Api.post(API_BASE_SUBMIT_USER, data)
}

export const getSettingValue = (key: string): Promise<string> => {
  console.log(key)
  // return Api.get(API_SETTING, {}, { key })
  return new Promise((resolve) => resolve(''))
}

export const updateSetting = (key: string, value: string) => {
  return Api.put(API_SETTING, { key, value }, { key: '' })
}

export const getAccountExecutive = (name: string) => {
  return Api.get(API_ACCOUNT_EXECUTIVE, { name })
}

export const submitAccountExecutive = (id: number, data: Record<string, any>) => {
  return Api.post(API_ACCOUNT_EXECUTIVE_BY_ID, data, { id })
}

export const submitFundDrawRequest = (data: Record<string, any>) => {
  return Api.post(API_FUND_DRAW_REQUEST, data, {})
}

export const getFaq = () => {
  return Api.get(API_FAQ)
}

export const getAccountExecutives = () => {
  return Api.get(API_ACCOUNT_EXECUTIVES)
}
