import { Carousel } from 'flowbite-react'

export const XsCarousel = (props: any) => {
  return (
    <>
      <div className="block xs:hidden">
        <Carousel slideInterval={3000}>{props.children}</Carousel>
      </div>
      <div className={props.className}>{props.children}</div>
    </>
  )
}
