import { ClockIcon } from '@heroicons/react/24/outline'
import { useRef } from 'react'
import type { Color } from 'stories/types'

interface ToggleButtonProps {
  /**
   * Id of Input
   */
  id: string
  /**
   * Which one is selected
   */
  value?: boolean
  /**
   * button label
   */
  options?: string[]
  /**
   * optional title
   */
  title?: string
  /**
   * What background color to use
   */
  color?: Color
  /**
   * How large should the button be?
   */
  size?: 'sm' | 'md' | 'lg'
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Error of Input
   */
  error?: string
  /**
   * Optional history handler
   */
  history?: boolean
  /**
   * Custom class name
   */
  className?: string
  /**
   * Show text on left
   */
  textLeft?: boolean
  /**
   * Optional click handler
   */
  onChange?: (checked: any) => void
  onBlur?: () => void
  showHistory?: () => void
}

export const ToggleButton = ({
  id = '',
  options = ['Yes', 'No'],
  title,
  size = 'sm',
  disabled = false,
  error = '',
  history = false,
  value,
  className = '',
  textLeft = false,
  onChange = () => {},
  onBlur = () => {},
  showHistory = () => {},
}: ToggleButtonProps) => {
  const classes1 = [
    `border border-indigo-500`,
    value === true ? `bg-indigo-500 text-white` : `bg-white text-gray-900`,
    `font-${size}`,
    `text-${size}`,
    'px-2',
    'py-1.5',
    'items-center justify-center',
    'w-11',
    'h-9',
    disabled && 'cursor-not-allowed',
  ]
  const classes2 = [
    `border border-indigo-500`,
    `border-l-0`,
    value === false ? `bg-indigo-500 text-white` : `bg-white text-gray-900`,
    `font-${size}`,
    `text-${size}`,
    'px-2',
    'py-1.5',
    'items-center justify-center',
    'w-11',
    'h-9',
    disabled && 'cursor-not-allowed',
  ]

  const textComponent = (
    <span
      className={`${textLeft ? 'mr' : 'ml'}-3 text-[14.5px] font-medium text-gray-900 dark:text-gray-300 flex-auto`}
    >
      {title}
    </span>
  )

  const changeValue = (value: boolean) => {
    onChange(value)
    setTimeout(() => {
      inputRef?.current?.click()
    }, 250)
  }

  const inputRef = useRef<HTMLDivElement>(null)

  return (
    <div className={`${className}`} id={id}>
      <div className={`flex items-center group relative`}>
        {textLeft ? textComponent : null}
        <div className="flex">
          <button type={'button'} className={classes1.join(' ')} disabled={disabled} onClick={() => changeValue(true)}>
            <span>{options[0]}</span>
          </button>
          <button type={'button'} className={classes2.join(' ')} disabled={disabled} onClick={() => changeValue(false)}>
            <span>{options[1]}</span>
          </button>
        </div>
        {!textLeft ? textComponent : null}
        {history && (
          <div className="flex flex-none w-5">
            <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
              <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
            </span>
          </div>
        )}
      </div>
      <div ref={inputRef} className="hidden" onClick={onBlur} />
      {error && <p className="peer-invalid:visible text-rose-700 text-[13px] pt-[1px] pl-1">{error}</p>}
    </div>
  )
}
