import './style.scss'

import { useTitle } from 'utils'

export const TermsOfServicePage = () => {
  useTitle('Terms Of Service')

  return (
    <div className="content pt-16 content-terms-of-service">
      <p className="text-4xl font-semibold mb-10 text-center">Terms Of Service</p>

      <section>
        <h2>Our Commitment to Fair Lending</h2>
        <p>
          Nextres, LLC's commitment to the fair treatment of all our customers and potential customers is an integral
          part of our overall commitment to maintaining the highest standards of corporate responsibility in all
          activities we undertake. This commitment extends to every aspect of a credit transaction, from advertising and
          pre-application inquiries to loan disbursement and ongoing services. But our focus on fairness reaches beyond
          our credit products to the equal treatment of all who come to Nextres, LLC for financial services.
        </p>
        <p>
          This policy contains information concerning fair lending, diversity, and the importance of equal treatment to
          Nextres, LLC’ success in the marketplace.
        </p>
        <p>
          Employees are expected to adhere to these principles and honor our corporate commitment, not simply because in
          many cases the law requires such behavior, but because it reflects our corporate values and makes good
          business sense. Please take the time to familiarize yourself with the information contained in the sections
          below.
        </p>
      </section>
      <section>
        <h2>What Is Fair Lending?</h2>
        <p>
          Fair lending is the consistent, objective, unbiased treatment of all customers. Nextres, LLC's focus on fair
          lending practices reflects its ongoing commitment to provide all customers and potential customers with equal
          access to credit. It is a focus that requires all of us to be aware that fair lending is not just the law—
          fair and consistent treatment of all our customers is the right thing to do and makes good business sense.
        </p>
      </section>
      <section>
        <h2>What Is Discrimination on a Prohibited Basis?</h2>
        <p>
          If we make a credit, pricing, or other decision on a prohibited basis, it is a violation of the fair lending
          laws and regulations. It is discrimination, it is illegal, and it prevents Nextres, LLC from meeting its
          marketplace goals. Three types of illegal discrimination recognized by the fair lending enforcement and
          regulatory agencies are described below
        </p>
      </section>
      <section>
        <h2>Overt Discrimination</h2>
        <p>
          Overt discrimination is blatant discrimination on a prohibited basis. Not making loans to women is an example
          of overt discrimination. The Fair Housing Act and Equal Credit Opportunity Act (ECOA) prohibit discrimination
          in lending on many bases. In addition, state laws or local ordinances may prohibit discrimination on
          additional bases. For example, a New York state law prohibits discrimination in lending based on sexual
          orientation.
        </p>
      </section>
      <section>
        <h2>Disparate Treatment</h2>
        <p>
          Disparate treatment means treating an applicant differently based on any of the prohibited bases. If it
          appears that a lender has treated similar applicants differently based on a prohibited factor, the lender must
          be able to provide a credible and legitimate non-discriminatory explanation. If an agency determines that an
          institution's explanation for treating some applicants differently is a pretext for discrimination, then the
          regulatory or investigating agency may infer that the lender has discriminated.
        </p>
      </section>
      <section>
        <h2>Disparate Impact</h2>
        <p>
          When a lender applies the same policy to all applicants, but that policy adversely affects a protected class,
          disparate impact exists. Evidence of discriminatory intent is not necessary to establish that disparate impact
          has occurred. Where the policy or practice is justified by business necessity and there is no less
          discriminatory alternative, a fair lending violation will not exist. When an agency finds that a lender's
          policy or practice has a disparate impact, as a practical matter, the lender bears the burden of showing that
          the policy or practice is justified by business necessity.
        </p>
      </section>
      <section>
        <h2>Fair Lending Regulations</h2>
        <h3>Equal Credit Opportunity Act – Regulation B (ECOA)</h3>
        <p>
          ECOA covers all personal and business loans. It affects every phase of the lending process and prohibits
          discrimination based on:
        </p>
        <ul>
          <li>Age</li>
          <li>Color</li>
          <li>Marital Status</li>
          <li>National Origin</li>
          <li>Race</li>
          <li>Religion</li>
          <li>Receipt of Public Assistance</li>
          <li>Sex</li>
          <li>Exercising Rights Under Consumer Protection Laws</li>
        </ul>
        <p>
          It is illegal to discourage, decline a request for or terminate a loan based on any of the above factors.{' '}
          <b>Note:</b> Nextres, LLC's corporate policy also includes sexual orientation as a prohibited basis.
        </p>
      </section>
      <section>
        <h2>Fair Housing Act (FHA)</h2>
        <p>
          The Fair Housing Act was passed to protect consumers from unfair practices relating to home sales, rentals or
          home financing. Specifically, the Fair Housing Act prohibits discrimination based on:
        </p>
        <ul>
          <li>Race</li>
          <li>Color</li>
          <li>Religion</li>
          <li>National origin</li>
          <li>Disability</li>
          <li>Familial status (e.g. presence of children in household)</li>
          <li>Sex</li>
        </ul>
      </section>
      <section>
        <h2>Americans With Disabilities Act (ADA)</h2>
        <p>
          The ADA prohibits discrimination against individuals with disabilities. It assures that these individuals have
          equal access to goods and services offered by private businesses, including financial institutions.
        </p>
      </section>
      <section>
        <h2>Civil Rights Act of 1866</h2>
        <p>
          The Civil Rights Act of 1866 provides that all persons "shall have the same right...to make and enforce
          contracts...and to the full and equal benefit of all laws and proceedings for the security of persons and
          property." As equal rights to real and personal property are guaranteed by the Civil Rights Act, our policies
          and procedures must safeguard against racial discrimination in every step of the lending process.
        </p>
      </section>
      <section>
        <h2>Home Mortgage Disclosure Act (HMDA)</h2>
        <p>
          HMDA is a "disclosure" law that requires mortgage lenders to report information annually about applications
          for home purchase, refinancing and home improvement loans. This reporting allows both the public and the
          regulators to determine responsiveness to the home financing needs of communities in which business is
          conducted. It requires that information about income, race, sex, geographic area, pricing and other factors be
          reported.
        </p>
      </section>
      <section>
        <h2>Community Reinvestment Act (CRA)</h2>
        <p>
          The CRA requires banks to help meet the credit and banking needs of the communities in which they do business,
          with a particular focus on low and moderate-income communities. CRA performance is measured and rated against
          the amount of mortgage and small business loans, community development investments and the extent of banking
          and community development services provided in low-and moderate-income markets, relative to what the bank is
          doing in non-low-and moderate-income markets and to the opportunities that exist in such markets. There are
          four possible CRA ratings: Outstanding, Satisfactory, Needs to Improve and Substantial Non-Compliance. The
          goal at Nextres, LLC is to be rated Outstanding.
        </p>
      </section>
      <section>
        <h2>Federal Trade Commission Act Section 5 - Unfair Deceptive Acts or Practices</h2>
        <p>
          This Act prohibits unfair methods of competition and unfair or deceptive acts or practices in or affecting
          commerce. It has recently been used to address abusive or predatory lending practices, such as loan flipping
          (repeated refinancing with no net benefit to the customer) and loan packing (adding on costly features such as
          single premium life insurance without the customer's full understanding.
        </p>
      </section>
      <section>
        <h2>Marketing</h2>
        <p>
          The compliance department, with assistance from the administrative and marketing departments, review and
          approve all marketing strategies that include, but are not limited to, signage, flyers, business cards,
          websites, and social media outlets prior to distribution and posting as public content. The review protects
          all class applicants and minority communities to ensure compliance with fair lending laws. The compliance
          department periodically reviews existing marketing strategies to confirm that they remain in compliance.
        </p>
      </section>
      <section>
        <h2>Loan Products</h2>
        <p>
          Nextres, LLC specializes in Conventional and Government (FHA, VA and USDA) mortgage loan programs in order to
          provide the best array of consumer products to meet the consumers specific needs. There are procedures in
          place to ensure that consumers obtain the best programs to meet their mortgage needs and credit
          qualifications. Our Compliance department is involved in all discussions regarding new and updated loan
          programs being offered as well as reviews for program availability and/or limitations for possible disparate
          impact. Quarterly review of Home Mortgage Disclosure Act is conducted within our Compliance department to
          monitor any discrepancies. Findings and analytics are reviewed to include loan program offerings across
          protected classes and demographic breakdowns. Results are reviewed timely for any necessary corrective action.
        </p>
      </section>
      <section>
        <h2>Underwriting</h2>
        <p>
          Nextres, LLC uses underwriting guidelines to promote and ensure consistency in the treatment of all classes of
          applicants. The guidelines address all aspects of the underwriting policies and procedures that include
          collateral, credit and income standards, source of funds, income documentation, and other relevant factors.
          Nextres, LLC offers its borrowers the best available products for which the borrower qualifies. Loan
          applicants that are rejected by Nextres, LLC are referred to underwriters for a second review prior to formal
          denial of credit. All rejected and withdrawn applications are sent for monthly review by the Operations
          Supervisor.
        </p>
      </section>
      <section>
        <h2>Monitoring</h2>
        <p>
          Nextres, LLC has a monitoring process and procedure in place to review the lending practices of the company,
          as well as individual departments. The compliance department focuses on detecting deficiencies and ensuring
          that personnel understand all responsibility they carry. The compliance department performs regular monthly
          audits of loan files to monitor data for funded and non-funded loans. In addition to the monthly review of
          withdrawn and rejected files by the Operations Supervisor, the compliance department performs regular monthly
          audits of loan files to monitor data for funded and non-funded loans.
        </p>
      </section>

      <section>
        <h2>Who Are the Fair Lending Enforcement Agencies?</h2>
        <ul>
          <li>The Department of Justice (DOJ)</li>
          <li>Department of Housing and Urban Development (HUD)</li>
          <li>Federal Trade Commission (FTC)</li>
        </ul>
        <p>
          Enforcement agencies investigate alleged fair lending violations. The Department of Justice becomes involved
          if a federal regulatory agency refers a supervised financial institution because of a possible "pattern or
          practice," but each agency may investigate or commence legal action on its own, often based on customer
          complaints.
        </p>
      </section>
      <section>
        <h2>What Are the Penalties for Non-Compliance with Fair Lending Laws and Regulations?</h2>
        <p>
          Possible consequences of non-compliance with fair lending laws and regulations can be extremely severe and
          include:
        </p>
        <ul>
          <li>Civil money penalties</li>
          <li>CRA rating downgrades</li>
          <li>Department of Justice investigations</li>
          <li>Litigation</li>
          <li>Adverse Publicity</li>
          <li>Cease and desist orders</li>
        </ul>
      </section>
      <section>
        <h2>Refinance and Collection Practice</h2>
        <p>
          Nextres, LLC is committed to implementing policies and procedures as well as management oversight to ensure
          professional and equitable treatment of all debtors. The policies and procedures include promptly responding
          to consumer inquires, concerns, or complaints in accordance with Executive Law 296-a.
        </p>
      </section>
      <section>
        <h2>Vendor Management Monitoring</h2>
        <p>
          Nextres, LLC has implemented vendor management processes in addition to its careful monitoring to mitigate
          against inadvertent or deliberate noncompliance. This process ensures that third parties providing services to
          Nextres, LLC are compliant with fair lending laws, including New York Executive law § 296-a. by obtaining
          written agreements from all third-party vendors and providers certifying and acknowledging their intent and
          responsibility to comply with New York Executive law § 296-a and all written policy and procedure outlined in
          this plan.
        </p>
      </section>
      <section>
        <h2>Fair Lending and Diversity</h2>
        <p>
          Building a workplace where differences are respected and valued is critical to our future and has been an
          important part of the traditions of Nextres, LLC. We've taken pride in our diversity efforts and the
          recognition we've garnered both inside and outside our company for steps taken to ensure an inclusive culture.
          For us, the business case is simple:
        </p>
        <p>
          Nextres, LLC will be a place for talented people from all backgrounds and nationalities. Gender, race, sexual
          orientation, age and physical ability are just some of the kinds of differences that make people unique as
          individuals and give us the diversity of perspective that will set us apart. Whether competing locally or
          globally, Nextres, LLC needs every employee to contribute his or her best to serve clients well and to
          engender a working environment of respect, fair treatment and teamwork.
        </p>
      </section>
      <section>
        <h2>Company Training</h2>
        <p>
          The Compliance Committee, with assistance from the administration department, conducts training for all new
          and current employees including all senior management and key personnel. This training is mandatory at hire
          and conducted annually thereafter. The training exposes employees to guidelines under the Equal Credit
          Opportunity Act, Regulation B, Regulation C, Regulation Z, the Home Mortgage Disclosure Act, and TILA. All
          employees are provided a copy of this plan at hire, and updates are distributed annually. Upon completion of
          the training, each employee, including all senior management and key personnel, must certify that as an
          Nextres, LLC employee they fully understand and fully commit to upholding all policy and procedure of this
          Fair Lending Plan as well as the principles of Executive Law § 292-a.
        </p>
        <p>
          This policy reflects current fair lending laws and requirements and it applies to and is practiced by all
          Nextres, LLC covered mortgage products and loan transactions.
        </p>
        <p>
          Nextres, LLC’s Board will continue review of this fair lending plan as our ongoing effort to continue
          compliance with the foregoing in addition to all rules, regulations, guidelines and state laws.
        </p>
      </section>
    </div>
  )
}
