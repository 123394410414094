import cloneDeep from 'clone-deep'
import type { InputType } from 'config'
import { states } from 'config/states.constants'
import { useEffect, useState } from 'react'
import { getItemsFromFullAddress, InputValidate, RenderInput } from 'utils'

import { Modal } from '../Modal/Modal'

export const defaultInputs = (): Record<string, InputType> => {
  return {
    street: {
      title: 'Street',
      inputType: 'text',
      value: '',
      error: '',
      required: true,
    },
    city: {
      title: 'City',
      inputType: 'text',
      value: '',
      error: '',
      required: true,
    },
    state: {
      title: 'State',
      inputType: 'select',
      options: states,
      value: '',
      error: '',
      hasDefaultOption: true,
      required: true,
    },
    zip: {
      title: 'Zip',
      inputType: 'text',
      type: 'number',
      value: '',
      error: '',
      required: true,
    },
  }
}

export const EditAddressDialog = ({ address, onSubmit }: { address: string; onSubmit: Function }) => {
  const [inputs, setInputs] = useState(defaultInputs())

  useEffect(() => {
    getItemsFromFullAddress(address).then((addrObj: any) => {
      if (!addrObj) return

      const newInputs = cloneDeep(inputs)
      newInputs.street.value = addrObj.street_address1 || ''
      newInputs.city.value = addrObj.city || ''
      newInputs.state.value = addrObj.state || ''
      newInputs.zip.value = addrObj.postal_code || ''
      setInputs(newInputs)
    })
  }, [address])

  const onChangeInputs = (key: string, value: string) => {
    const newInputs = cloneDeep(inputs)
    newInputs[key].value = value
    newInputs[key].error = ''
    setInputs(newInputs)
  }

  const onOk = () => {
    let hasError = false
    const newStats = cloneDeep(inputs)
    const inputData: Record<string, any> = {}
    for (const key in newStats) {
      const { value } = newStats[key]
      let error = InputValidate(newStats[key])
      newStats[key].error = error
      if (error.length > 0) hasError = true

      if (value !== undefined) inputData[key] = value
    }
    if (hasError) {
      setInputs(newStats)
      return
    }

    const address = [inputData.street, inputData.city, `${inputData.state} ${inputData.zip}`].join(', ')
    onSubmit(address)

    return true
  }

  const onClose = () => {
    onSubmit(null)
  }

  return (
    <Modal isOpen title="Edit Address" titleOkay="Ok" onOk={onOk} onClose={onClose}>
      <div className={`text-gray-600 text-md w-96`}>
        {Object.keys(inputs).map((key, index) => {
          const input = inputs[key]
          return (
            <div key={index} className="input mb-4">
              <RenderInput input={input} Key={key} onChange={onChangeInputs} />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}
