import _imgBlackHome from './blackhome.png'
import _imgCircleGraph from './circlegraph.svg'
import _imgDownsideRisk from './downsiderisk.png'
import _svgDownsideRisk from './downsiderisk.svg'
import _downsideRiskProtection from './downsideRiskProtection.png'
import _downsideRiskProtectionItem from './downsideRiskProtectionItem.png'
import _svgExclusive from './exclusive.svg'
import _imgGrayHome from './grayhome.png'
import _imgGreenGraph from './greengraph.svg'
import _imgHeaderLeft from './header-left.svg'
import _imgHigherReturn from './higherReturns.png'
import _svgHigherReturn from './higherReturns.svg'
import _iconLenderPartnerNetwork from './iconLenderPartnerNetwork.svg'
import _iconMonthlyReturnsGuaranteed from './iconMonthlyReturnsGuaranteed.svg'
import _iconPrincipalReturnReinvestment from './iconPrincipalReturnReinvestment.svg'
import _iconSharedLoanVentures from './iconSharedLoanVentures.svg'
import _iconYouRemainInControlLock from './iconYouRemainInControlLock.svg'
import _imgIntuitiveTool from './intuitiveTools.png'
import _imgInvestin from './investin.jpg'
import _imgKnowgoingon from './knowgoingon.svg'
import _imgNoSiteVisits from './noSiteVisits.png'
import _svgNoSiteVisits from './noSiteVisits.svg'
import _signUpRight from './signUpRight.png'
import _youRemainInControl from './youRemainInControl.jpg'
import _imgYouRemainInControl0 from './youRemainInControl0.png'
import _imgYouRemainInControl1 from './youRemainInControl1.png'
import _imgYouRemainInControl2 from './youRemainInControl2.png'
import _imgYourSafetyFirst from './yourSafetyFirst.png'

export const imgHeaderLeft = _imgHeaderLeft
export const imgKnowgoingon = _imgKnowgoingon

export const imgDownsideRisk = _imgDownsideRisk
export const imgHigherReturn = _imgHigherReturn
export const imgIntuitiveTool = _imgIntuitiveTool
export const imgNoSiteVisits = _imgNoSiteVisits

export const svgDownsideRisk = _svgDownsideRisk
export const svgHigherReturn = _svgHigherReturn
export const svgNoSiteVisits = _svgNoSiteVisits

export const imgInvestIn = _imgInvestin

export const iconLenderPartnerNetwork = _iconLenderPartnerNetwork
export const iconMonthlyReturnsGuaranteed = _iconMonthlyReturnsGuaranteed
export const iconSharedLoanVentures = _iconSharedLoanVentures
export const iconPrincipalReturnReinvestment = _iconPrincipalReturnReinvestment

export const imgYourSafetyFirstAlways = _imgYourSafetyFirst
export const imgHomeDownsideRiskProtection = _downsideRiskProtection
export const imgHomeDownsideRiskProtectionItem = _downsideRiskProtectionItem
export const svgExclusive = _svgExclusive
export const imgYouRemainInControl = _youRemainInControl

export const imgYouRemainInControl0 = _imgYouRemainInControl0
export const imgYouRemainInControl1 = _imgYouRemainInControl1
export const imgYouRemainInControl2 = _imgYouRemainInControl2
export const iconYouRemainInControlLock = _iconYouRemainInControlLock

export const imgSignUpRight = _signUpRight

export const imgGrayHome = _imgGrayHome
export const imgCircleGraph = _imgCircleGraph
export const imgBlackHome = _imgBlackHome
export const imgGreenGraph = _imgGreenGraph
