import { useNavigate } from 'react-router-dom'
import { Button } from 'stories/components'

export const SignUpInputs = () => {
  const navigate = useNavigate()

  return (
    <div className="flex gap-2 xs:w-64 w-full mb-1">
      <Button color="indigo" full className="font-medium py-3.5 px-6" onClick={() => navigate('/auth/signUp')}>
        Get Started
      </Button>
    </div>
  )
}
