import { ClockIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import { googleMapApiKey } from 'config'
// import { setLoanData } from 'actions'
import { useEffect, useRef, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
// import { useDispatch } from 'react-redux'
import { Tooltip } from 'stories/components'

import { EditAddressDialog } from './EditAddressDialog'

interface GoogleAutoCompleteProps {
  /**
   * Is disabled
   */
  disabled?: boolean
  /**
   * Is readOnly
   */
  readOnly?: boolean
  /**
   * Title of Input
   */
  title?: string
  /**
   * Placeholder of Input
   */
  placeholder?: string
  /**
   * Tooltip of Input
   */
  tooltip?: string
  /**
   * Value of Input
   */
  value?: string
  /**
   * Input key of Input
   */
  inputKey?: string
  /**
   * Error of Input
   */
  error?: string
  /**
   * Custom class name
   */
  className?: string

  inputClassName?: string
  /**
   * Show History
   */
  history?: boolean
  /**
   * Required
   */
  required?: boolean
  /**
   * Editable
   */
  editable?: boolean

  isLegacy?: boolean

  onChange: (e: any) => void // string | React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  showHistory?: () => void
}

/**
 * Primary UI component for user interaction
 */
export const GoogleAutoComplete = ({
  disabled = false,
  readOnly = false,
  title = '',
  placeholder = ' ',
  tooltip = '',
  value: _value = '',
  inputKey = 'google-map-auto',
  inputClassName = '',
  error = '',
  required = false,
  isLegacy = true,
  editable = true,
  className = '',
  history = false,
  onChange = () => {},
  onBlur = () => {},
  showHistory = () => {},
}: GoogleAutoCompleteProps) => {
  const classNames = [
    'block',
    inputClassName.includes('rounded') ? '' : 'rounded',
    inputClassName.includes('py-') ? '' : 'py-1.5',
    inputClassName.includes('px-') ? '' : 'px-2',
    'w-full',
    'text-sm',
    'text-gray-900',
    disabled ? 'bg-gray-100' : 'bg-white',
    readOnly ? 'cursor-not-allowed' : '',
    'border',
    'border-gray-300',
    'focus:outline-none',
    'focus:ring-0',
    `focus:border-sky-600`,
    'peer',
    error && 'border-rose-700',
    className,
    inputClassName,
  ]
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState<string | null>(null)
  const [isShowEdit, setShowEdit] = useState(false)
  useEffect(() => {
    setValue(_value)
  }, [_value])

  useEffect(() => {
    if (value !== null && value != _value) onChange(value)
  }, [value])

  useEffect(() => {
    if (inputRef.current) (inputRef.current as any).value = value
  }, [value])

  // const dispatch = useDispatch()

  const change = (e: any, isFromBlur = false) => {
    let value: string = e.target.value
    if (value.endsWith('USA')) {
      const index = value.lastIndexOf(', USA')
      value = value.substring(0, index)
    }
    console.log(value)
    setValue(value)
    if (!isFromBlur) {
      inputRef?.current?.focus()
      setTimeout(() => {
        if (document.activeElement === inputRef.current) {
          inputRef?.current?.blur()
        }
      }, 250)
    }
    if (inputKey === 'subjectPropertyAddress') {
      if (e.target.county) {
        // dispatch(setLoanData({ key: 'propertyCounty', data: e.target.county }))
      }
    }
  }

  const editAddress = () => {
    setShowEdit(true)
  }

  const onChangeText = async (address: string) => {
    if (address) {
      const e = {
        target: {
          value: address,
        },
      }
      change(e)
    }
    setShowEdit(false)
  }

  if (value === null) return <div />

  return (
    <div className="input-container w-full">
      <div className={`group relative z-0 w-full`}>
        {isLegacy && title && (
          <p className="font-medium mb-1">
            {title} {required && '*'}
          </p>
        )}

        {readOnly ? (
          <input className={classNames.join(' ')} disabled={true} value={value} />
        ) : (
          <Autocomplete
            ref={inputRef}
            apiKey={googleMapApiKey}
            onPlaceSelected={(place) => {
              if (place.address_components) {
                let county = ''
                let locality = ''
                for (let i = 0; i < place.address_components.length; i += 1) {
                  const item = place.address_components[i]
                  if (item.types.indexOf('administrative_area_level_2') !== -1) county = item.long_name
                  if (item.types.indexOf('locality') !== -1) locality = item.long_name
                }
                if (county.length === 0 && locality.length) {
                  county = locality + ' city'
                }
                const e = {
                  target: {
                    value: place.formatted_address,
                    county: county,
                    state: place.address_components[5]?.short_name || '',
                  },
                }
                change(e)
              }
            }}
            onBlur={(e: any) => {
              change(e, true)
              onBlur()
            }}
            className={classNames.join(' ')}
            inputAutocompleteValue={value}
            defaultValue={value}
            placeholder={placeholder}
            options={{ types: ['address'], componentRestrictions: { country: 'us' } }}
            key={`google-map-auto-${inputKey}`}
          />
        )}

        {!isLegacy && title && (
          <label className="absolute text-[12px] text-gray-700 top-1.5 border-b z-10 origin-[0] left-2.5 flex gap-2 items-center">
            {title}
            {required && '*'}
            {tooltip.length > 0 ? <Tooltip message={tooltip}></Tooltip> : null}
            {history && (
              <span className="ml-1 hidden group-hover:inline" onClick={() => showHistory()}>
                <ClockIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
              </span>
            )}
            {editable && (
              <span className="ml-1 hidden group-hover:inline" onClick={() => editAddress()}>
                <PencilSquareIcon className="h-[14px] w-[14px] text-gray-500 cursor-pointer" aria-hidden="true" />
              </span>
            )}
          </label>
        )}
      </div>
      {error && <p className="peer-invalid:visible text-rose-700 text-xs pl-1">{error}</p>}

      {editable && isShowEdit && <EditAddressDialog address={value} onSubmit={onChangeText} />}
    </div>
  )
}
