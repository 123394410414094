import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAccountExecutives } from 'services/apis'
import { useTitle } from 'utils'

interface IDbAccountExecutive {
  createdAt: string
  description: string
  email: string
  facebookUrl: string
  firstName: string
  lastName: string
  id: number
  linkedInUrl: string
  phone: string
  photo: string
  photoUrl: string
  title: string
  workPhone: string
  visible: boolean
}

export const AccountExecutivesListPage = () => {
  useTitle('Account Executives')

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<IDbAccountExecutive[]>([])

  useEffect(() => {
    reload()
  }, [])

  const reload = () => {
    setLoading(true)
    getAccountExecutives()
      .then((data) => setData(data))
      .finally(() => setLoading(false))
  }

  return (
    <div className="w-full">
      <div className="content">
        <LayoutLoading show={isLoading} />
        <div className="px-5 pb-12 max-w-screen-xl m-auto mt-20">
          <p className="capitalize text-center text-4xl font-semibold text-gray-900 mb-8">Account Executives</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {data.map((item) => (
              <Link to={`/account_executives/${item.firstName}${item.lastName}`} key={item.id}>
                <div className="bg-white rounded-lg shadow-md p-6 my-6 text-center">
                  <img src={item.photoUrl} alt="Team Member 1" className="w-full rounded-full mb-4 aspect-square object-cover" />
                  <h3 className="text-xl font-semibold mb-2">
                    {item.firstName} {item.lastName}
                  </h3>
                  <p className="text-gray-700">{item.title}</p>
                  <div className="flex items-center justify-center gap-6 mt-2">
                    {!!item.linkedInUrl && (
                      <a
                        className="flex items-center gap-2 text-indigo-500 hover:underline cursor-pointer"
                        href={item.linkedInUrl}
                      >
                        <svg className="h-4 w-4 rounded-sm fill-indigo-500" viewBox="0 0 24 24">
                          <path d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z" />
                        </svg>
                      </a>
                    )}
                    {!!item.facebookUrl && (
                      <a
                        className="flex items-center gap-2 text-indigo-500 hover:underline cursor-pointer"
                        href={item.facebookUrl}
                      >
                        <svg className="h-4 w-4 rounded-sm fill-indigo-500" viewBox="0 0 24 24">
                          <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
                        </svg>
                      </a>
                    )}
                    <a href={`mailto:${item.email}`} className="flex items-center gap-2 text-gray-700">
                      <EnvelopeIcon className="w-4 h-4 text-indigo-500" />
                    </a>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
