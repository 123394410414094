import type { AccountType } from 'config'

type tpNavigation = {
  name: string
  href: string
  visible: boolean
  allow?: AccountType[]
  className?: string
}

const draftNavigations: Record<string, tpNavigation> = {
  lenders: { name: 'Lenders', href: '/lenders', visible: true },
  learn_more: { name: 'Learn More', href: '/learn_more', visible: true },
  account_executives: { name: 'Account Executives', href: '/account_executives', visible: true },

  span1: {
    name: '',
    href: '',
    visible: true,
    className: 'flex-1',
  },
  fund_draw_request: { name: 'Fund Draw Request', href: '/fund_draw_request', visible: true },
  text: {
    name: '(858) 299-5570',
    href: '#',
    visible: true,
    className: 'px-3 border-b-4',
  },
  signin: {
    name: 'Borrower Login',
    href: 'https://commercial.nextres.com/borrowerPipelines/signin',
    visible: true,
    className: 'px-6 py-2 bg-indigo-500 rounded text-white hover:bg-indigo-700',
  },
}

export const getNavigations = () => {
  return draftNavigations
}
