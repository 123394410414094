import './home.scss'

import {
  ArrowUpIcon,
  ChartBarIcon,
  CreditCardIcon,
  MagnifyingGlassIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline'
import {
  iconLenderPartnerNetwork,
  iconMonthlyReturnsGuaranteed,
  iconPrincipalReturnReinvestment,
  iconSharedLoanVentures,
  iconYouRemainInControlLock,
  imgBlackHome,
  imgCircleGraph,
  imgDownsideRisk,
  imgGrayHome,
  imgHeaderLeft,
  imgHigherReturn,
  imgHomeDownsideRiskProtection,
  imgHomeDownsideRiskProtectionItem,
  imgIntuitiveTool,
  imgInvestIn,
  imgKnowgoingon,
  imgLogoWhite,
  imgNoSiteVisits,
  imgSignUpRight,
  imgYouRemainInControl,
  imgYouRemainInControl0,
  imgYouRemainInControl1,
  imgYouRemainInControl2,
  imgYourSafetyFirstAlways,
  svgDownsideRisk,
  svgExclusive,
  svgHigherReturn,
  svgNoSiteVisits,
} from 'assets'
import { SignUpInputs, XsCarousel } from 'components'
import { Image } from 'components/Image'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'stories/components'
import { useTitle } from 'utils'

export function HomePage() {
  useTitle('Invest in high-yield, premium real-estate secured loans')

  const navigate = useNavigate()

  const howItWorks = [
    [
      'Structure',
      'Upload all required documents within the structure screen, and an underwriter will be assigned to examine your file.',
      iconLenderPartnerNetwork,
    ],
    [
      'Order Valuation',
      'Complete the valuation to determine the fair market value of the property so our underwriters can make a precise assessment.',
      iconSharedLoanVentures,
    ],
    [
      'Underwriting',
      'Once the valuation, preliminary title commitment, and hazard insurance are submitted, your loan can officially move to underwriting.',
      iconMonthlyReturnsGuaranteed,
    ],
    [
      'Closing',
      'Once all underwriting conditions are satisfied, you’re clear to close!',
      iconPrincipalReturnReinvestment,
    ],
  ]

  return (
    <div className="">
      <div className="w-full">
        <div className="content flex flex-col lg:flex-row gap-4 mt-6 md:mt-10 lg:mt-0">
          <div className="flex flex-col flex-1 justify-center mb-4 sm:mb-8 md:mb-14 lg:mb-0">
            <div className="relative max-w-lg">
              <img src={imgHeaderLeft} className="absolute w-64 left-0 -top-12" />
              <div className="relative">
                {/* <p className="text-sm font-semibold mb-4 uppercase text-gray-700">High-Yield Property Investing</p> */}
                <p className="text-3xl mb-5 font-playfair">The Power of Smart Investment Lending</p>

                <p className="text-md leading-6 mb-5 text-gray-800">
                  With cutting-edge technology, and a streamlined process, Nextres gets you to the finish line FAST and
                  provides you with the financing you need for your next investment property.
                </p>

                <div className="lg:pr-6 mb-8">
                  <SignUpInputs />
                </div>

                {/* <div className="xs:text-sm text-xs text-gray-800">
                  <p className="mb-2">Backed By:</p>
                  <div className="flex items-center gap-2">
                    <img src={LogoE} className="h-5" />
                    <span className="uppercase">Entrepreneurs Roundtable Accelerator</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="flex-[1.2] relative xs:-mx-4 md:-mx-10 lg:mx-0 overflow-hidden rounded-xl xs:rounded-none mb-8 xs:mb-0 -mx-2">
            <Image src={imgInvestIn} className="w-full block object-none md:object-cover aspect-[2.2/1.8]" />

            <div className="absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] block text-green-500">
              <div className="relative bg-white px-3 py-2 flex items-center rounded-lg border border-white w-24">
                <ArrowUpIcon className="w-4 h-4 mr-2" />
                10.5%
                <div className="border-l-2 border-l-white absolute left-[50%] top-10 h-8" />
              </div>

              <div className="ml-72 relative bg-white px-3 py-2 flex items-center rounded-lg border border-white w-24">
                <ArrowUpIcon className="w-4 h-4 mr-2" />
                11.18%
                <div className="border-l-2 border-l-white absolute left-[50%] top-10 h-8" />
              </div>

              <div className="relative bg-white px-3 py-2 flex items-center rounded-lg border border-white w-24 mx-auto mt-14 mb-20">
                <ArrowUpIcon className="w-4 h-4 mr-2" />
                9.75%
                <div className="border-l-2 border-l-white absolute left-[50%] top-10 h-8" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full py-8 sm:py-12 md:py-20 bg-gray-800 text-center text-gray-200 md:mb-10 mb-6">
        <div className="content">
          <p className="font-playfair text-3xl leading-10 mb-8 lg:mb-12">
            A variety of unique products suitable for every need.
          </p>
          <XsCarousel className="hidden xs:grid lg:grid-cols-4 grid-cols-2 gap-2 md:gap-4 lg:gap-2 -mx-2 xs:mx-0 sm:mx-10 lg:mx-0 xl:mx-20">
            {[
              [imgDownsideRisk, svgDownsideRisk, 'Fix & Flip', 'Purchase, renovate, make a profit!'],
              [
                imgIntuitiveTool,
                <div className="text-white uppercase absolute top-0 left-0 w-full h-full">
                  <div className="relative border border-white/50 rounded-xl ml-auto xs:mt-4 mt-2 xs:mr-[10%] mr-[5%] xs:w-[43%] w-32 lg:w-28 xl:h-24 lg:h-20 md:w-36 md:h-28 h-20 p-2 text-center">
                    <div className="text-sm font-light mb-auto flex items-baseline justify-center gap-1">
                      <p className="lg:text-3xl md:text-5xl text-3xl font-medium">2</p>{' '}
                      <p className="lg:text-xs md:text-base text-xs">months</p>
                    </div>
                    <p className="lg:text-xs md:text-sm text-xs font-light absolute bottom-2 w-full left-0 text-center">
                      Time Remaining
                    </p>
                  </div>
                  <div className="xs:w-[80%] w-[90%] mx-auto absolute xs:left-[10%] left-[5%] bottom-[46%] text-left">
                    <p className="lg:text-3xl md:text-5xl text-3xl md:mb-2 lg:mb-1 xs:mb-2 mb-1 font-medium">70%</p>
                    <p className="lg:text-2xs md:text-sm sm:text-xs xs:text-xs text-base font-light md:mb-2 lg:mb-1 xs:mb-2 mb-1">
                      Funded by 138 investors
                    </p>
                    <div className="border border-white/50 rounded-lg">
                      <div className="w-[70%] border border-white rounded-lg md:h-3 lg:h-2 sm:h-2.5 h-2" />
                    </div>
                  </div>
                </div>,
                'Bridge',
                'Short-term financing for purchase or cash out.',
              ],
              [
                imgHigherReturn,
                <>
                  <img src={svgHigherReturn} className="absolute top-0 left-0 w-full" />
                  <div className="text-white text-center absolute left-0 w-full bottom-[53%]">
                    <p className="text-5xl md:text-6xl lg:text-5xl mb-2">11.5%</p>
                    <p className="uppercase xs:text-xs text-base font-light md:font-normal">Annual Return</p>
                  </div>
                </>,
                'DSCR',
                'Maximize monthly rental income while your property appreciates.',
              ],
              [
                imgNoSiteVisits,
                svgNoSiteVisits,
                'Ground Up Construction',
                'Not a fan of current inventory? Tear down and build from the ground up.',
              ],
            ].map((data, index) => (
              <div className="relative" key={index}>
                <Image src={data[0] as any} className="w-full rounded-3xl" />
                <div className="bg-black/60 absolute top-0 left-0 w-full bottom-0 rounded-3xl" />
                {typeof data[1] == 'string' ? <img src={data[1]} className="absolute top-0 left-0 w-full" /> : data[1]}
                <div className="text-white absolute w-full bottom-0 lg:px-1 px-2 lg:text-base md:text-xl sm:text-base xs:text-sm text-base rounded-4xl">
                  <p className="font-semibold mb-1">{data[2]}</p>
                  <p className="mb-4 lg:h-14 md:h-20 sm:h-14 h-12 font-light pb-20 xs:pb-0">{data[3]}</p>
                </div>
              </div>
            ))}
          </XsCarousel>
        </div>
      </div>

      <div className="w-full">
        <div className="content lg:mb-24 sm:mb-16 mb-8">
          <p className="text-3xl mb-8 font-playfair text-center w-full mt-4 lg:py-8 sm:py-6 py-4 font-medium">
            Why Us?
          </p>
          <div className="mx-0 xl:mx-20">
            <div className="border-2 rounded-2xl grid md:grid-cols-2 grid-cols-1 items-center mx-0 mb-6 lg:mb-10 overflow-hidden">
              <div className="flex-1 flex items-center xs:px-6 px-4 md:px-6 lg:px-14 xl:px-16">
                <div className="inline-block py-16 ml-auto mr-auto">
                  <div className="relative mb-20">
                    <Image src={imgGrayHome} />
                    <div className="absolute right-0 -bottom-10 rounded-md shadow-lg p-4 max-w-xs bg-white">
                      <Image src={imgCircleGraph} className="absolute -top-14 -right-12 w-32" />
                      <p className="text-gray-700 text-sm mb-2">Loan Overview</p>
                      <div className="grid grid-cols-2 text-sm font-semibold">
                        <div>
                          <p className="mb-2">Property Price</p>
                          <p className="text-base">$390,000</p>
                        </div>
                        <div>
                          <p className="mb-2">Deposit</p>
                          <p className="text-base">
                            $39,000
                            <span className="bg-emerald-50 text-emerald-500 px-1 py-0.5 ml-1">10%</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="font-playfair text-3xl mb-4">The Secret To Our Success</p>
                  <p className="text-md text-gray-700 max-w-md">
                    In an ever-changing market, we know the importance of moving swiftly and efficiently. That’s why our
                    team of seasoned professionals make your needs their top priority. With 24-hour underwriting, a
                    variety of products, and same day approvals, we provide fast financing solutions.
                  </p>
                </div>
              </div>
              <div className="flex-1 flex items-center xs:px-6 px-4 md:px-6 lg:px-14 xl:px-16 bg-black/90">
                <div className="inline-block py-16 md:py-24 ml-auto mr-auto">
                  <div className="relative mb-20">
                    <Image src={imgBlackHome} />
                  </div>
                  <p className="font-playfair text-3xl mb-4 text-gray-100">You Remain In Control</p>
                  <p className="text-md text-gray-100 max-w-md mb-4">
                    It's not our style to tell our investors what to do. We'd much rather provide you with all the tool,
                    and resources for making informed decisions about your desired investments. No fancy Jargon or
                    misleading tactics. You cherry pick your investments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full lg:mt-24 md:mt-16 sm:mt-12 mt-2 mb-8 lg:mb-14 xl:mb-32 text-center relative">
        <div className="border-t-0 md:border-t-4 border-t-gray-200 border-l-4 md:border-l-0 border-l-gray-200 absolute top-0 bottom-0 left-10 sm:left-20 md:left-0 md:top-[calc(50%+7rem)] lg:top-[50%] md:w-full">
          <div className="content md:-mt-8">
            <div className="md:grid md:grid-cols-8 lg:grid-cols-12 xl:mx-20">
              <div className="col-span-4 hidden lg:block" />

              <div className="col-span-8 hidden md:grid grid-cols-9">
                {[1, 2, 3, 4].map((v) => (
                  <>
                    <div />
                    <div>
                      <p
                        className={`block mx-auto rounded-full p-3 w-16 h-16 aspect-square ${
                          v == 1 ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-800'
                        } text-3xl`}
                      >
                        {v}
                      </p>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="content mb-10">
          <div className="lg:grid grid-cols-12 xl:mx-20 ml-20 sm:ml-24 md:ml-0">
            <div className="col-span-3 flex items-center md:mb-12 mb-6 lg:mb-0">
              <div className="inline-block md:mt-12 sm:mt-8 mt-4">
                <p className="font-playfair text-3xl text-left md:mb-10 mb-6">See How It Works.</p>
                <p className="text-left text-sm">
                  Nextres has a straightforward and transparent process to complete the necessary steps to fulfill a
                  loan. Our team is here to guide you every step of the way.
                </p>
              </div>
            </div>
            <div />

            <div className="col-span-8 pl-2 sm:pl-10 md:px-0">
              <div className="md:grid grid-cols-9 mb-8 sm:mb-16 md:mb-28">
                {howItWorks.map((data, index) => (
                  <>
                    <div
                      className={`col-span-3 ${
                        index % 2 == 0 ? 'flex' : 'flex md:hidden'
                      } flex-col md:flex-col-reverse gap-4 mb-4 md:mb-0 relative max-w-sm`}
                    >
                      <div className="absolute md:hidden -left-24 sm:-left-28 top-8">
                        <p
                          className={`mx-auto rounded-full p-3 w-16 h-16 aspect-square ${
                            index == 0 ? 'bg-indigo-500 text-white' : 'bg-gray-200 text-gray-800'
                          } text-3xl`}
                        >
                          {index + 1}
                        </p>
                      </div>

                      <div className="border border-gray-800 rounded-lg items-center overflow-hidden flex flex-col">
                        <Image src={data[2]} className="m-8 w-12 h-12" />
                        <p className="p-1 bg-gray-100 border-t border-t-gray-800 w-full whitespace-nowrap	text-ellipsis overflow-hidden ">
                          {data[0]}
                        </p>
                      </div>
                      <p className="text-sm px-5">{data[1]}</p>
                    </div>
                    <div className={`${index % 2 == 0 ? '' : 'hidden'}`} />
                  </>
                ))}
              </div>

              <div className="hidden md:grid grid-cols-9">
                <div />

                {howItWorks
                  .filter((_, index) => index % 2 == 1)
                  .map((data) => (
                    <>
                      <div />
                      <div className="col-span-3 flex flex-col gap-4">
                        <div className="border border-gray-800 rounded-lg items-center overflow-hidden flex flex-col">
                          <Image src={data[2]} className="m-8 w-12 h-12" />
                          <p className="p-1 bg-gray-100 border-t border-t-gray-800 w-full whitespace-nowrap	text-ellipsis overflow-hidden ">
                            {data[0]}
                          </p>
                        </div>
                        <p className="text-sm">{data[1]}</p>
                      </div>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="content lg:mb-24 sm:mb-16 mb-8">
          <div className="mx-0 xl:mx-20">
            {[
              [
                'Vetted loans only',
                'Providing Solutions Is What We Do Best',
                `At Nextres, we understand that life happens! Here’s a few ways we provide assistance to our borrowers:`,
                imgYourSafetyFirstAlways,
                <>
                  <div className="absolute top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%] w-[90%] xs:w-80 md:w-72 lg:w-80">
                    <div className="bg-white rounded-lg mb-2 text-center p-4">
                      <img src={imgLogoWhite} className="w-12 h-12 mx-auto mb-2" />
                      <p>Loan Request Verification</p>
                    </div>
                    <div className="rounded-xl overflow-hidden">
                      <p className="bg-gray-800 px-6 py-3 text-white">John Doe</p>
                      <div className="px-6 py-4 bg-white">
                        <div className="flex justify-between mb-2">
                          <p className="bg-green-100 text-green-500 p-2 py-1 rounded-lg">Fund</p>
                          <p className="text-green-500 text-lg">$200,000</p>
                        </div>
                        <div className="flex justify-between mb-6">
                          <p>Status</p>
                          <p>Loan is approved</p>
                        </div>
                        <p className="text-gray-400 text-center">Diligence Process Passed</p>
                      </div>
                    </div>
                  </div>
                </>,
              ],
              [
                'Priority for Your Money',
                'Maturity Bailouts',
                `Bailouts for borrowers in foreclosure OR borrowers whose loans have hit maturity, with terms that will not be extender by their lender.`,
                imgHomeDownsideRiskProtection,
                <div className="absolute md:w-72 sm:w-96 w-72 top-[50%] -translate-y-[50%] left-[50%] -translate-x-[50%]">
                  <Image
                    src={imgHomeDownsideRiskProtectionItem}
                    className="md:object-none sm:object-contain w-full mx-auto"
                  />
                  <img src={svgExclusive} className="aspect-square absolute -top-10 -right-14 w-32" />

                  <p className="absolute text-white bottom-[53%] px-6 w-full left-0 md:text-sm sm:text-lg text-sm">
                    Financing home development in Vero Beach, FL
                  </p>
                  <div className="flex flex-col gap-4 absolute h-[48%] bottom-0 w-full py-4 px-6 justify-around">
                    <div className="uppercase flex gap-6">
                      <div>
                        <p className="text-gray-500 md:text-xs sm:text-base text-xs mb-1">Risk Level</p>
                        <span className="px-2 py-1 bg-yellow-200 md:text-sm sm:text-lg text-sm rounded-lg font-semibold">
                          Low
                        </span>
                      </div>
                      <div>
                        <p className="text-gray-500 md:text-xs sm:text-base text-xs mb-1">Investment Value</p>
                        <p className="md:text-base sm:text-xl text-base font-semibold">$250,000</p>
                      </div>
                    </div>
                    <div>
                      <div className="bg-indigo-200 rounded-full mb-1">
                        <div className="bg-indigo-500 w-[56%] h-2 rounded-full" />
                      </div>
                      <div className="flex justify-between text-gray-500 md:text-sm sm:text-lg text-sm">
                        <p>Progress</p>
                        <p>56%</p>
                      </div>
                    </div>
                  </div>
                </div>,
              ],
              [
                'Self Directed Investing',
                'Assistance With Derogatory Events',
                `A fresh start at 50% LTV for borrowers who have hit a bump in the road and payments can no longer be accepted by their lender.`,
                imgYouRemainInControl,
                <div className="absolute left-[calc(50%+1rem)] -translate-x-[50%] top-[58%] -translate-y-[50%] flex">
                  {[imgYouRemainInControl0, imgYouRemainInControl1, imgYouRemainInControl2].map((backImg, index) => (
                    <div className="relative md:w-[17rem] xs:w-[23rem] w-[17rem] -ml-6" key={index}>
                      <Image
                        src={backImg}
                        className="mx-auto md:w-[17rem] xs:w-[23rem] w-[17rem] md:object-none xs:object-contain object-none"
                      />

                      <p className="absolute text-white bottom-[51%] md:px-6 xs:px-10 px-6 w-full left-0 md:text-sm sm:text-lg text-sm">
                        Financing home development in Vero Beach, FL
                      </p>
                      <div className="flex flex-col gap-4 absolute h-[30%] bottom-[14%] w-full md:px-6 xs:px-10 px-6 justify-around">
                        <div className="uppercase flex gap-6">
                          <div>
                            <p className="text-gray-500 md:text-xs sm:text-base text-xs mb-1">Risk Level</p>
                            <span className="md:text-base sm:text-xl text-base font-semibold">Low</span>
                          </div>
                          <div>
                            <p className="text-gray-500 md:text-xs sm:text-base text-xs mb-1">Investment Value</p>
                            <p className="md:text-base sm:text-xl text-base font-semibold">$250,000</p>
                          </div>
                        </div>
                        <div>
                          <div className="bg-indigo-200 rounded-full mb-1">
                            <div className="bg-indigo-500 w-[56%] h-2 rounded-full" />
                          </div>
                          <div className="flex justify-between text-gray-500 md:text-sm sm:text-lg text-sm">
                            <p>Progress</p>
                            <p>56%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <img
                    src={iconYouRemainInControlLock}
                    className="aspect-square absolute top-[-17%] left-[calc(50%-0.5rem)] -translate-x-[50%] w-32"
                  />
                </div>,
              ],
            ].map((data, index) => (
              <div
                className="border-2 rounded-2xl flex flex-col-reverse md:flex-row items-center mx-0 mb-6 lg:mb-10 overflow-hidden gap-4 md:gap-6 sm:gap-10 lg:gap-16 xl:gap-20"
                key={index}
              >
                <div className="flex-1  flex items-center py-4 xs:py-6 sm:py-2 xs:px-6 px-4 md:px-6 lg:px-14 xl:px-16">
                  <div className="inline-block">
                    {/* <div className="mb-5">
                      <span className="text-sm bg-gray-200 text-gray-700 p-2 rounded-md">{data[0]}</span>
                    </div> */}
                    <p className="font-playfair text-3xl mb-4">{data[1]}</p>
                    <p className="text-md text-gray-700">{data[2]}</p>
                  </div>
                </div>
                <div className="flex-1 xl:flex-[1.1] w-full md:w-auto relative overflow-hidden">
                  <Image
                    src={data[3] as any}
                    className={`w-full ${
                      index == 0 ? 'xs:h-full h-88' : 'h-full'
                    } object-none xs:object-contain md:object-none lg:object-cover`}
                  />
                  {data[4]}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full relative mb-8 lg:mb-16 bg-gray-800 lg:py-20 md:py-14 py-10">
        <div className="content">
          <div className="flex flex-col lg:flex-row mb-12 sm:ml-10 lg:ml-0 xl:ml-20">
            <div className="flex-[2] flex items-center">
              <div className="inline-block">
                <div className="w-full mb-14 text-left text-gray-100">
                  <div className="mb-6">
                    <span className="text-sm font-light p-2 rounded-md bg-white/10">Real-Time Investment Tracking</span>
                  </div>
                  <p className="text-3xl font-playfair mb-6">Estimate Your Rate</p>
                  <p className="text-md mb-10">
                    It’s an investor’s market, and you need a strategy! With our predictive technology,{' '}
                    <Link to="https://www.nexys.com/home" target="_blank">
                      <span className="italic hover:border-b-2">Nexys</span>
                    </Link>
                    , we can generate a personalized rate sheet for you in a matter of minutes, present you your funding
                    options, and help you plan for the future, TODAY.
                  </p>
                  <Button color="indigo" className="px-6 py-3" onClick={() => navigate('/marketplace')}>
                    Explore Options
                  </Button>
                </div>
              </div>
            </div>
            <div className="flex-[3] -mx-4 md:mx-0">
              <img src={imgKnowgoingon} className="mx-auto" />
            </div>
          </div>

          <p className="text-gray-100 text-center mb-8 text-base">
            Thinking about the next steps? Good. They’re easy to take
          </p>
          <div className="grid grid-cols-1 2xs:grid-cols-2 lg:grid-cols-4 gap-3 text-center md:mx-20 mb-10">
            {[
              [UserPlusIcon, 'Online Account Registration'],
              [CreditCardIcon, 'Securely Link Bank Account'],
              [MagnifyingGlassIcon, 'Loan Selection Process'],
              [ChartBarIcon, 'Monitor Investment Earnings'],
            ].map((data, index) => {
              const Icon = data[0]
              return (
                <div className="py-4 sm:py-8 rounded-xl bg-white/5 text-gray-100 sm:text-base text-sm" key={index}>
                  <div className="rounded-full p-3 w-14 h-14 mx-auto mb-2">
                    <Icon className="w-8 h-8" />
                  </div>
                  <p className="mb-4">
                    <span className="bg-white/10 px-3 py-2 rounded-md">Step {index + 1}</span>
                  </p>
                  <p className="px-2">{data[1] as string}</p>
                </div>
              )
            })}
          </div>

          <div className="flex flex-col items-center">
            <div className="w-full xs:w-64 mx-auto">
              <SignUpInputs />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full relative">
        <div className="content">
          <div className="flex flex-col-reverse md:flex-row gap-6 md:gap-10 lg:gap-32 sm:ml-10 lg:ml-0 xl:ml-20">
            <div className="flex-1 px-0 md:px-10 md:mt-10">
              <p className="font-playfair text-2xl mb-4 md:mb-10">
                “Nextres’s high-interest real estate loans gave me true passive income, with complete control and no
                pressure to invest. It's seamless and easy.”
              </p>
              <p className="font-bold text-lg">John S</p>
              <p className="text-sm">Nextres investor</p>

              <div className="border-t my-4" />
              {/* <p className="text-2xl mb-4">Start Investing Today</p> */}

              <div className="w-full">
                <SignUpInputs />
              </div>
            </div>
            <div className="flex-1">
              <Image src={imgSignUpRight} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
