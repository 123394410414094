import { Footer, MainHeader } from 'stories/layouts'

export const BaseLayout = (props: any) => {
  const { children } = props

  return (
    <div className={`relative lg:w-full h-full-screen bg-gray-50`}>
      {/* <BaseHeader /> */}
      <MainHeader type="white" />
      <main className="mx-auto main">{children}</main>
      <Footer />
    </div>
  )
}
