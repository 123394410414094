import { AccountExecutivesPage } from 'pages/AccountExecutives'
import {
  AccountExecutivesListPage,
  FundDrawRequestPage,
  LearnMorePage,
  LendersPage,
  PrivacyPolicyPage,
  TermsOfServicePage,
} from 'pages/Base'
import { HomePage } from 'pages/Home'
import { Navigate, Route, Routes } from 'react-router-dom'

export const BasePages = () => {
  return (
    <>
      <Route path="/learn_more" element={<LearnMorePage />} />
      <Route path="/lenders" element={<LendersPage />} />
      <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms_of_service" element={<TermsOfServicePage />} />
      <Route path="/account_executives" element={<AccountExecutivesListPage />} />
      <Route path="/account_executives/:name" element={<AccountExecutivesPage />} />
      <Route path="/fund_draw_request" element={<FundDrawRequestPage />} />
    </>
  )
}

export const BaseRoutes = () => (
  <Routes>
    <Route path="/home" element={<HomePage />} />
    {BasePages()}
    <Route path="*" element={<Navigate replace to="/home" />} />
  </Routes>
)
