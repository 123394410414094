export const baseUrl = process.env.REACT_APP_API_URL

/**
 * Base
 */
export const API_BASE_SUBMIT_USER = '/home/submitUser'
export const API_SETTING = '/setting/:key'

export const API_ACCOUNT_EXECUTIVE = '/api/accountExecutive'
export const API_ACCOUNT_EXECUTIVE_BY_ID = '/api/accountExecutive/:id'
export const API_FUND_DRAW_REQUEST = '/webhooks/fundDrawRequest'

export const API_FAQ = '/api/landingLearnMore'
export const API_ACCOUNT_EXECUTIVES = '/api/accountExecutives'
