import _imgAuthFooter from './auth-footer.jpg'
import _imgCongratulations from './congratulations.png'
import _imgHouse0 from './houses/0.jpg'
import _imgHouse1 from './houses/1.jpg'
import _imgHouse2 from './houses/2.jpg'
import _imgHouse3 from './houses/3.jpg'
import _imgHouse4 from './houses/4.jpg'
import _imgHouse5 from './houses/5.jpg'
import _imgHouse6 from './houses/6.jpg'
import _imgHouse7 from './houses/7.jpg'
import _imgHouse8 from './houses/8.jpg'
import _imgHouse9 from './houses/9.jpg'
import _imgSampleHouse from './houses/sample.jpg'
import _svgIcon from './icon.svg'
import _LogoBlue from './logo.svg'
import _LogoWhite from './logo-white.svg'
// import _imgMapPin from './pin.png'

export const LogoBlue = () => <img src={_LogoBlue} className="w-48" />
export const LogoWhite = () => <img src={_LogoWhite} className="h-12" />
export const imgLogoWhite = _LogoWhite
export const imgLogo = _LogoBlue
export const imgSampleHouse = _imgSampleHouse
export const imgHouses = [
  _imgHouse0,
  _imgHouse1,
  _imgHouse2,
  _imgHouse3,
  _imgHouse4,
  _imgHouse5,
  _imgHouse6,
  _imgHouse7,
  _imgHouse8,
  _imgHouse9,
]
export const imgAuthFooter = _imgAuthFooter

export const imgCongratulations = _imgCongratulations

// export const imgMapPin = _imgMapPin

export * from './home'
export * from './howitworks'
export * from './icons'
export * from './lenders'

export const svgIcon = _svgIcon
